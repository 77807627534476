<template>
  <div>
    <h1>Lektionen</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
              rounded
              color="primary"
              dark
              @click="createChapter"
            >
              <v-icon left>mdi-plus</v-icon>
              Lektion
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="chapters"
        :search="search"
        :items-per-page="itemsPerPage"
        @update:options="updateOptions"
        class="elevation-1"
        :hide-default-footer="chapters.length < 5"
      >
        <template v-slot:item:name="{  }">
          <v-avatar
              color="primary"
              size="56"
          ></v-avatar>
        </template>
        <template v-slot:item.author="{ item }">
          <v-avatar
              v-if="item.author"
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{uppercaseFirstLetter(item.author.firstName)}}{{uppercaseFirstLetter(item.author.lastName)}}</v-avatar>
          <template v-if="item.author">{{item.author.firstName}} {{item.author.lastName}}</template>
        </template>
        <template v-slot:item.createdAt="{ item }" class="ml-0">
          {{ item.updatedAt | formatLocaleDate }}
        </template>
        <template v-slot:item.updatedAt="{ item }" class="ml-0">
          {{ item.updatedAt | formatLocaleDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <lesson-edit-dialog ref="lessonEditDialog" @updated="fetchChapters"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import LessonEditDialog from '@/components/LessonEditDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";

export default {
  name: 'LessonList',
  mixins: [vuetifyTableMixin, ],
  components: { LessonEditDialog, ConfirmDialog },
  data () {
    return {
      search: '',
      chapters: [],
      headers: [
        {
          text: 'Titel',
          align: 'start',
          sortable: true,
          value: 'title',
        },
        {
          text: 'Autor',
          value: 'author',
          sortable: true
        },
        {
          text: 'Erstellt am',
          value: 'createdAt',
          sortable: true
        },
        {
          text: 'Zuletzt bearbeitet am',
          value: 'updatedAt',
          sortable: true
        },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: true,
          align: 'end'
        }
      ],
    }
  },
  methods: {
    createChapter () {
      this.$refs.lessonEditDialog.create()
    },
    editItem (lesson) {
      APIService.getLesson(lesson).then(lesson => {
        this.$refs.lessonEditDialog.edit(lesson)
      })
    },
    async deleteItem (lesson) {
      await this.$refs.confirmDelete.show({
        title: 'Lektion entfernen',
        text: `Wollen sie <b>${lesson.title}</b> entfernen?`,
        confirm: 'entfernen'
      })
      await APIService.deleteLesson(lesson)
      this.chapters = await APIService.getChapters()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    uppercaseFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() : ''
    },
    async fetchChapters() {
      console.log("fetchLessons")
      this.chapters = await APIService.getLessons()
    }
  },
  async beforeRouteEnter(to, from, next) {
    const chapters = await APIService.getLessons()
    next(vm => vm.chapters = chapters)
  },
  async beforeRouteUpdate(to,from,next) {
    this.chapters = await APIService.getLessons()
    next()
  }
}
</script>

<style scoped>

</style>
