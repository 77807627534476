<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Lektion</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="chapterForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div v-if="lesson.id" class="lesson-info">
                    <div v-if="lesson.createdAt">
                      Erstellt am:
                      {{lesson.createdAt | formatLocaleDate}}
                    </div>
                    <div v-if="lesson.author">
                      Erstellt von:
                      {{lesson.author.firstName}} {{lesson.author.lastName}}
                    </div>
                    <div v-if="lesson.lastUpdatedBy">
                      Zuletzt bearbeitet von:
                      {{lesson.lastUpdatedBy.firstName}} {{lesson.lastUpdatedBy.lastName}}
                    </div>
                    <div v-if="lesson.updatedAt">
                      Zuletzt bearbeitet am:
                      {{lesson.updatedAt | formatLocaleDate}}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="lesson.title"
                      label="Titel"
                      required
                      :rules="chapterTitleRules()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <tiptap-vuetify
                      v-model="lesson.description"
                      :extensions="extensions"
                  />
                </v-col>
                <v-col cols="12">
                  <image-input
                      v-model="lesson.image"
                      label="Titelbild"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="lesson.courseLink"
                      label="Link zu Kursinhalten"
                      :append-icon="'mdi-window-close'"
                      @click:append="lesson.courseLink=''"
                  ></v-text-field>
                </v-col>
                <v-col v-if="content" cols="12">
                  <v-card-title>Inhalt:</v-card-title>
                  <v-card-text>
                    Name: {{content.originalName}}<br/>
                    Größe: {{(content.size / (1024*1024)).toFixed(2)}}MB<br/>
                    Typ: {{content.mimeType}}<br>
                    id: {{content.id}}<br/>
                    Pfad: {{content.path}}<br/>
                    <template v-if="content.sha256sum"> Checksum: {{content.sha256sum}}<br></template>
                  </v-card-text>
                </v-col>
                <v-col cols="12">
                  <file-upload @uploaded="fileUploaded" @started="uploadStarted"/>
                  <v-checkbox label="Inhalt darf von Teilnehmer heruntergeladen werden" v-model="lesson.isContentDownloadable"></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      :no-resize="true"
                      rows="2"
                      v-model="lesson.comment"
                      label="Kommentar"
                  />
                </v-col>
                <v-col v-if="lesson.assignedTo"  cols="12">
                  <div class="subtitle-1">Die Lektion ist folgenden Kapiteln zugeordnet:</div>
                  <div v-html="getChapterTitles()"/>
                 </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Abbrechen
          </v-btn>
          <v-btn
              :disabled="isUploading"
              color="blue darken-1"
              text
              @click="saveLesson"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"/>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import FileUpload  from "@/components/FileUpload";
import Snackbar from "@/components/Snackbar";

import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import ImageInput from "@/components/ImageInput";

export default {
  name: 'LessonEditDialog',
  components: { Snackbar, ConfirmDialog, TiptapVuetify, FileUpload, ImageInput },
  data() {
    return {
      showDialog: false,
      lesson: {},
      saveSuccess: false,
      isLessonTitleTaken: false,
      parentId: 0,
      snackbarSuccess: {
        text: 'Lektion erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      dragging: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      isUploading: false
    }
  },
  methods: {
    create () {
      this.lesson = {}
      this.showDialog = true
    },
    edit (lesson) {
      this.lesson = lesson
      this.showDialog = true
    },
    close() {
      this.lesson = {}
      this.$refs.chapterForm.resetValidation()
      this.showDialog = false
    },
    getChapterTitles() {
      return this.lesson.assignedTo ? this.lesson.assignedTo.map(lesson => lesson.title).join(', ') : ''
    },
    async saveLesson() {
      console.log("Save Lesson")
      if (!this.$refs.chapterForm.validate()) {
        return
      }
      await APIService.saveLesson(this.lesson)
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.$emit('updated')
      this.close()
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    chapterTitleRules() {
      return [
        value => !!value || 'Titel erforderlich.',
        !this.isLessonTitleTaken || 'Der Titel ist bereits vergeben.'
      ]
    },
    fileUploaded(fileMeta) {
      console.log("File uploaded", fileMeta)
      this.isUploading = false
      this.lesson = {...this.lesson, content: [{file: fileMeta, path: fileMeta.path}] }
    },
    uploadStarted() {
      this.isUploading = true
    }
  },
  computed: {
    ...mapState(['user']),
    content() {
      return this.lesson.content ? this.lesson.content.length > 0 ? this.lesson?.content[0].file : null : null
    }
  },
  watch: {
    "chapter.name"() {
      this.isLessonTitleTaken = false
    }
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
