<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Kapitel</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="chapterForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div v-if="chapter.id" class="chapter-info">
                    <div v-if="chapter.createdAt">
                      Erstellt am:
                      {{chapter.createdAt | formatLocaleDate}}
                    </div>
                    <div v-if="chapter.author">
                      Erstellt von:
                      {{chapter.author.firstName}} {{chapter.author.lastName}}
                    </div>
                    <div v-if="chapter.lastUpdatedBy">
                      Zuletzt bearbeitet von:
                      {{chapter.lastUpdatedBy.firstName}} {{chapter.lastUpdatedBy.lastName}}
                    </div>
                    <div v-if="chapter.updatedAt">
                      Zuletzt bearbeitet am:
                      {{chapter.updatedAt | formatLocaleDate}}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="chapter.title"
                      label="Titel"
                      required
                      :rules="chapterTitleRules()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <tiptap-vuetify
                      v-model="chapter.description"
                      :extensions="extensions"
                  />
                </v-col>
                <v-col cols="12">
                  <image-input
                      v-model="chapter.image"
                      label="Titelbild"
                  />
                </v-col>
                <v-col cols="12">
                  <v-expansion-panels
                      v-model="lessonPanel"
                      multiple
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>Lektionen</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <draggable
                            :list="selectedLessons"
                            class="list-group"
                            ghost-class="ghost"
                            @start="dragging = true"
                            @end="dragging = false"
                        >
                          <v-card
                              class="list-group-item"
                              v-for="lesson in selectedLessons"
                              :key="lesson.id"
                          >
                            <v-card-text>
                              <v-expansion-panels
                                  multiple
                                  flat
                              >
                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    <v-row
                                    >
                                      {{ lesson.title }}
                                      <v-spacer/>
                                      <v-icon
                                          class="mr-5"
                                          color="secondary"
                                          small
                                          @click.stop="removeLesson(lesson)"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </v-row>
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-row>
                                    <v-col cols="12">
                                    <span v-html="lesson.description"/>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field label="Alias-Titel für Kapitel" v-model="lesson.aliasTitle"/>
                                    </v-col>
                                    </v-row>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-card-text>
                          </v-card>
                        </draggable>
                        <v-row class="mt-2">
                          <v-col>
                            <v-autocomplete
                                v-model="selectedLesson"
                                :items="lessons"
                                cache-items
                                dense
                                class="mx-4"
                                hide-no-data
                                hide-details
                                label="Lektion"
                                item-text="title"
                                item-value="id"
                            ></v-autocomplete>
                          </v-col>
                          <v-col>
                            <v-btn
                                rounded
                                dense
                                color="primary"
                                :disabled="selectedLesson === null"
                                @click="addLesson"
                            >
                              Hinzufügen
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="chapter.courseLink"
                      label="Link zu Kursinhalten"
                      :append-icon="'mdi-window-close'"
                      @click:append="chapter.courseLink=''"
                  ></v-text-field>
                </v-col>
                <v-col v-if="content" cols="12">
                  <v-card-title>Inhalt:</v-card-title>
                  <v-card-text>
                    Name: {{content.originalName}}<br/>
                    Größe: {{(content.size / (1024*1024)).toFixed(2)}}MB<br/>
                    Typ: {{content.mimeType}}<br>
                    id: {{content.id}}<br/>
                    Pfad: {{content.path}}<br/>
                    <template v-if="content.sha256sum"> Checksum: {{content.sha256sum}}<br></template>
                  </v-card-text>

                </v-col>
                <v-col cols="12">
                  <file-upload @uploaded="fileUploaded" @started="uploadStarted"/>
                  <v-checkbox label="Inhalt darf von Teilnehmer heruntergeladen werden" v-model="chapter.isContentDownloadable"></v-checkbox>
                </v-col>
                <v-col v-if="chapter.assignedTo"  cols="12">
                  <div class="subtitle-1">Das Kapitel ist folgenden Kursen zugeordnet:</div>
                  <div v-html="getCourseTitles()"/>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      :no-resize="true"
                      rows="2"
                      v-model="chapter.comment"
                      label="Kommentar"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Abbrechen
          </v-btn>
          <v-btn
              :disabled="isUploading"
              color="blue darken-1"
              text
              @click="saveChapter"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import FileUpload  from "@/components/FileUpload";
import Snackbar from "@/components/Snackbar";

import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import draggable from "vuedraggable";
import ImageInput from "@/components/ImageInput";

export default {
  name: 'ChapterEditDialog',
  components: { Snackbar, draggable, ConfirmDialog, TiptapVuetify, FileUpload, ImageInput },
  data() {
    return {
      chapter: {},
      dragging: false,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      isChapterTitleTaken: false,
      lessons: [],
      parentId: 0,
      saveSuccess: false,
      selectedLesson: null,
      selectedLessons: [],
      showDialog: false,
      snackbarSuccess: {
        text: 'Lektion erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      lessonPanel: false,
      search: '',
      isUploading: false
    }
  },
  methods: {
    addLesson() {
      if(this.selectedLessons.find((chapter) =>  chapter.id === this.selectedLesson)) {
        return
      }
      this.selectedLessons.push({...this.lessons.find((lesson) => lesson.id === this.selectedLesson)})
      this.selectedLesson = null
    },
    async create () {
      this.chapter = {}
      this.lessons = await APIService.getLessons()
      this.showDialog = true
    },
    async edit (chapter) {
      this.chapter = chapter
      this.lessons = await APIService.getLessons()
      this.selectedLessons = this.chapter.lessons.map(lesson => ({
        ...this.lessons.find(_lesson => lesson.lesson === _lesson.id),
        ...lesson,
      }))
      this.showDialog = true
    },
    close() {
      this.chapter = {}
      this.selectedLesson = null
      this.selectedLessons = []
      this.lessonPanel = false
      this.$refs.chapterForm.resetValidation()
      this.showDialog = false
    },
    chapterTitleRules() {
      return [
        value => !!value || 'Titel erforderlich.',
        !this.isChapterTitleTaken || 'Der Titel ist bereits vergeben.'
      ]
    },
    getCourseTitles() {
      return this.chapter.assignedTo ? this.chapter.assignedTo.map(lesson => lesson.title).join(', ') : ''
    },
    fileUploaded(fileMeta) {
      this.isUploading = false
      this.chapter = {...this.chapter, content:[ {file: fileMeta, path: fileMeta.path}]  }
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    removeLesson(lesson) {
      this.selectedLessons = this.selectedLessons.filter((_lesson) => lesson.id !== _lesson.id)
    },
    async saveChapter () {
      if (!this.$refs.chapterForm.validate()) {
        return
      }
      this.chapter.lessons = this.selectedLessons.map((lesson) => ({
        lesson: lesson.id,
        ...lesson.aliasTitle ?  { aliasTitle: lesson.aliasTitle } : {},
      }))
      await APIService.saveChapter(this.chapter)
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.$emit('updated')
      this.close()
    },
    uploadStarted() {
      this.isUploading = true
    }
  },
  computed: {
    ...mapState(['user']),
    content() {
      return this.chapter.content ? this.chapter.content.length > 0 ? this.chapter?.content[0].file : null : null
    }
  },
  watch: {
    "chapter.name"() {
      this.isChapterTitleTaken = false
    }
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
